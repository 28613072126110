import { getMapsHelper } from './util/mapsHelper';

export const initHomeMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#home_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<span class="map_pin">
							<img src="/dist/images/icons/ic_mappin.svg" alt="" />
						</span>
						`
					};
				},
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};